import React from 'react'
import Helmet from 'react-helmet'

import Gallery from '../components/Gallery'
import Layout from '../components/layout'

const Tech = () => {
  const siteTitle = 'TECH'
  const siteDescription = 'Tech work '

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
      </Helmet>

      <div id="main">
        <section id="one">
          <header className="major">
            <h2>
              Technology
            </h2>
          </header>
          <p>
            A contract based Site Reliability Engineer, currently working in fintech in highly regulated environments.
            <br/>
            Skills
            <ul>
            <li>
                Python
            </li>
            <li>
                Golang
            </li>
            <li>
                AWS
            </li>
            <li>
                Docker and Docker Orchestration
            </li>
            <li>
                DevOps and SRE in compliance environments
            </li>
            </ul>
          </p>
          <ul className="actions">
            <li>
              <a href="#" className="button">
                Learn More
              </a>
            </li>
          </ul>
        </section>

        {/* <section id="two">
          <h2>Recent Work and Projects</h2>

          <Gallery />

          <ul className="actions">
            <li>
              <a href="#" className="button">
                Full Portfolio
              </a>
            </li>
          </ul>
        </section> */}

        <section id="three">
          <h2>Get In Touch</h2>
          <p>
            I am available to consult on small to medium projects with DevOps/SRE needs.
            I also work with some other excellent engineers at <a href="https://initpath.com">initpath</a> who provide consultancy services
          </p>
          <div className="row">
            <div className="8u 12u$(small)">
              <form method="post" action="https://getform.io/f/9cd57fbd-d883-4534-89d3-efd27c98d595">
                <div className="row uniform 50%">
                  <div className="6u 12u$(xsmall)">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name"
                    />
                  </div>
                  <div className="6u 12u$(xsmall)">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="12u">
                    <textarea
                      name="message"
                      id="message"
                      placeholder="Message"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
                <ul className="actions" style={{ marginTop: 30 }}>
                  <li>
                    <input type="submit" value="Send Message" />
                  </li>
                </ul>
              </form>
            </div>
            <div className="4u 12u$(small)">
              <ul className="labeled-icons">
                {/* <li>
                  <h3 className="icon fa-home">
                    <span className="label">Address</span>
                  </h3>
                  1234 Somewhere Rd.
                  <br />
                  Nashville, TN 00000
                  <br />
                  United States
                </li>
                <li>
                  <h3 className="icon fa-mobile">
                    <span className="label">Phone</span>
                  </h3>
                  000-000-0000
                </li> */}
                <li>
                  <h3 className="icon fa-envelope-o">
                    <span className="label">Email</span>
                  </h3>
                  <a href="#">hello@robintaylor.org</a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Tech
